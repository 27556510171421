import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { useTranslation } from 'react-i18next'
import { localizeDictionary } from 'shared/lib/common'
import { SpinElement } from 'shared/ui/kit'
import { DictionaryItem } from 'types/IDictionary'

import SiteMultiSelect from './ui/SiteMultiSelect'

type Props = {
  value: DictionaryItem[]
  onChange: (sites: DictionaryItem[]) => void
}
export const SiteMultiSelectContainer = ({ onChange, value }: Props) => {
  const { t } = useTranslation()
  const dictionary = useLiveQuery(() => getDataFromDB('SITE')) as TDict
  const { data } = { ...dictionary }
  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, EDictionaryNS.SITE)

  const onAddSite = (site: DictionaryItem) => {
    onChange([...value, site])
  }
  const onDeleteSite = (site: DictionaryItem) => {
    onChange(value.filter((item) => item.id !== site.id))
  }

  return dictionaryLocales ? (
    <SiteMultiSelect
      sites={dictionaryLocales}
      selectedSites={value}
      onDeleteSite={onDeleteSite}
      onAddSite={onAddSite}
      title={t('Локализация')}
    />
  ) : (
    <SpinElement spinning />
  )
}
