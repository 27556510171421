import './style.css'

import generatePicker, { RangePickerDateProps } from 'antd/es/date-picker/generatePicker'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import { FC } from 'react'
import { getDatePickerLocale } from 'shared/lib/date/getDatePickerLocale'
import i18next from 'shared/lib/i18n/i18n'
import { IconElement } from 'shared/ui/kit'

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)
const { RangePicker } = DatePicker

const { language } = i18next
const dateLocale = getDatePickerLocale(language)

type Props = RangePickerDateProps<Date> & {
  getPopupContainer?: (trigger: HTMLElement) => HTMLElement
}

export const DateRangePicker: FC<Props> = ({ getPopupContainer, open, ...rest }) => (
  <div className="submenu-filter-datarange-style">
    <RangePicker
      nextIcon={<IconElement size={'md'} name="navigateRight" />}
      prevIcon={<IconElement size={'md'} name="navigateLeft" />}
      superNextIcon={<IconElement size={'md'} name="navigateRightDouble" />}
      superPrevIcon={<IconElement size={'md'} name="navigateLeftDouble" />}
      locale={dateLocale}
      popupStyle={{
        borderRadius: 5,
        padding: 0,
      }}
      open={open}
      getPopupContainer={getPopupContainer}
      {...rest}
    />
  </div>
)
