import { db } from 'app/indexedDB'
import { dictionaryService } from 'features/dictionaries/api/service'
import { DictionaryId, DictionaryItem, DictionaryVersion } from 'types/IDictionary'

export type TDict<T = DictionaryItem[]> = {
  updatedAt: string
  data: T
}

export type TDIctICD10 = {
  updatedAt: string
  data: {
    group2Icd10Codes: Record<string, DictionaryItem[]>
  }
}

//@ts-ignore
const dbDictionaries = db.dictionaries

/**
 * Справочники, которые не будут отображены.
 * (пока что пустые в Гемотест)
 */
export const unusedDictionaries: DictionaryId[] = ['MATERIAL_TYPE', 'PROCESS_NATURE']

const getDictionaryData = async (version: DictionaryVersion) => {
  try {
    const dictionaryCache = await dbDictionaries.where('id').equals(version.identifier).toArray()
    let updatedAt
    if (dictionaryCache.length > 0) {
      updatedAt = dictionaryCache[0].data.updatedAt
    }
    if (updatedAt !== version.updatedAt) {
      const dictionaryData = await dictionaryService.getDictionaryItems(version.identifier)
      await dbDictionaries.delete(version.identifier)

      await dbDictionaries.add({
        data: {
          data: dictionaryData,
          updatedAt: version.updatedAt,
        },
        hash: version.hash,
        id: version.identifier,
      })
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const clearDictionaries = () => dbDictionaries.clear()

export const restoreDictionary = async (dictionaryInfo: DictionaryVersion[]) => {
  try {
    /** массив справочников в базе */
    const dictionaryCache = await dbDictionaries?.toArray()
    /** создаем множество (Set) хэшей идентификаторов из dictionaryInfo(полученных справочников по воркспейсу) */
    const dictionaryInfoSet = new Set(dictionaryInfo?.map((item) => item.hash + item.identifier))
    /** Фильтруем элементы из базы(кэша), которых нет в dictionaryInfo */
    const missingItems = dictionaryCache?.filter((item: any) => !dictionaryInfoSet?.has(item.hash + item.id))

    const promises = missingItems?.map((version: any) => dbDictionaries?.delete(version.id))

    await Promise.allSettled(promises)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const initDictionaries = async (workspaceId?: string) => {
  try {
    const dictionaryInfo = await dictionaryService.getDictionaryInfo(workspaceId)
    if (dictionaryInfo === undefined) return
    await restoreDictionary(dictionaryInfo)
    const promises = dictionaryInfo?.map((version) => getDictionaryData(version))
    await Promise.allSettled(promises)
  } catch (e) {
    console.log(e)
    throw e
  }
}
