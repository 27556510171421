import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { localizeDictionary } from 'shared/lib/common'
import i18next from 'shared/lib/i18n/i18n'
import { DictionaryItem } from 'types/IDictionary'

import StainSelectElement from './ui/StainSelectElement'

const t = i18next.t

type Props = {
  isDisabled?: boolean
  onChange?: (stain: DictionaryItem) => void
  value?: DictionaryItem
  placeholder?: string
  width?: number | string
  onFocus?: () => void
  onBlur?: () => void
  /** Окраска по-умолчанию **/
  initialStane?: string
}

export const StainSelectContainer = ({
  initialStane,
  isDisabled,
  onBlur,
  onChange,
  onFocus,
  placeholder = t('Поиск по названию или коду'),
  value,
  width = '100%',
}: Props) => {
  useTranslation(EDictionaryNS.STAIN)
  const dictionary = useLiveQuery(() => getDataFromDB('STAIN')) as TDict

  const { data, updatedAt } = { ...dictionary }

  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, EDictionaryNS.STAIN)

  useEffect(() => {
    if (initialStane && data) {
      //TODO
      // @ts-ignore
      const initialDictionaryItem: DictionaryItem = data.find((item) => item.shortName === initialStane)
      initialDictionaryItem && onChange && onChange(initialDictionaryItem)
    }
  }, [initialStane, data])
  const onSelect = useCallback(
    (id: number) => {
      if (dictionaryLocales) {
        const stain = dictionaryLocales.find((item) => item.id === id)
        if (onChange && stain) {
          onChange(stain)
        }
      }
    },
    [updatedAt],
  )

  return dictionaryLocales ? (
    <StainSelectElement
      style={{ width }}
      value={value?.id || value?.name || undefined}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onSelect as any}
      isDisabled={isDisabled}
      stains={Array.from(dictionaryLocales.values())}
      stain={value?.id ? value : undefined}
    />
  ) : null
}
