import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchElement } from 'shared/ui/kit'

interface CasesTableSearchProps {
  /** Строка поиска */
  query?: string
}

const CasesTableSearch: FC<CasesTableSearchProps> = ({ query }) => {
  const { t } = useTranslation()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { setTableQuery } = useCaseManagementContext()

  /**  при смене таба сбрасываем поле поиска */
  useEffect(() => {
    setTableQuery('')
  }, [currentTab, menuTab])

  const handleTableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableQuery(event.target.value)
  }

  return (
    <SearchElement
      data-testid="case-table-search-input"
      placeholder={`${t('Поиск по таблице')}...`}
      value={query}
      onChange={handleTableSearch}
      style={{ width: 190 }}
    />
  )
}

export default CasesTableSearch
