import { useTypedSelector } from 'app/redux/lib/selector'
import { getCurrentPageIndex } from 'features/cases-management/lib/helpers'
import { useDeleteUploadFiles, useRestoreBinTrash } from 'features/sump'
import { useUploadedFilesAvailableFiltersQuery } from 'features/uploaded-file/api/query'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { getBarcodeFailText, getFileTypeText, getUsers } from 'pages/uploaded-file/lib/renderHandlers'
import { viewerPageSlice } from 'pages/viewer'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useManagementTab } from 'shared/lib/hooks/useQueryParams'
import { ButtonElement, Center, IconElement } from 'shared/ui/kit'
import { FilterType, ISubMenu, t } from 'shared/ui/table/lib/common'
import { TTabItem } from 'shared/ui/tabs/TableTabs'
import styled from 'styled-components/macro'
import { EUploadedFileAttachmentState, EUploadedFileTab, EUploadedFileType } from 'types/IUploadedFile'

import { createUFSubMenu, EUploadedFileFilterType } from './common'

export const useUploadedFileQueryParams = () => {
  const currentTab = useManagementTab<EUploadedFileTab>()

  return { currentTab }
}

export const useUploadedFileFilters = () => {
  const { currentTab } = useUploadedFileQueryParams()
  const [dataMenuConfig, setDataMenuConfig] = useState<ISubMenu[]>([])
  const wsId = Number(useCurrentWorkspaceId())
  const { data: availableFilters } = useUploadedFilesAvailableFiltersQuery(wsId, currentTab)

  const baseFilters = useMemo(
    () =>
      [
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key: EUploadedFileFilterType.CREATED_DATE,
          title: t('Время загрузки'),
          visible: true,
        },
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key: EUploadedFileFilterType.ATTACHED_TO_CASE_DATE,
          title: t('Время разбора'),
          visible: currentTab === EUploadedFileTab.ATTACHED_TO_CASE,
        },
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key: EUploadedFileFilterType.DELETED_DATE,
          title: t('Время переноса'),
          visible: currentTab === EUploadedFileTab.DELETED,
        },
      ].filter((it) => it.visible),
    [currentTab],
  )

  useEffect(() => {
    if (!availableFilters) return

    const filters: ISubMenu[] = []
    const { fileTypes, states, users } = availableFilters

    if (fileTypes?.length) {
      filters.push(
        createUFSubMenu<EUploadedFileType>(
          EUploadedFileFilterType.UPLOADED_FILE_TYPES,
          t('Тип'),
          fileTypes,
          FilterType.CHECKBOX,
          getFileTypeText,
        ),
      )
    }

    if (states?.length) {
      filters.push(
        createUFSubMenu<EUploadedFileAttachmentState>(
          EUploadedFileFilterType.UPLOADED_FILE_ATTACHMENT_STATES,
          t('Проблема'),
          states,
          FilterType.CHECKBOX,
          getBarcodeFailText,
        ),
      )
    }

    filters.push(
      createUFSubMenu<number>(
        EUploadedFileFilterType.ATTACHED_TO_CASE_BY_USER,
        t('Разобрано'),
        getUsers(users).map((user) => user.value),
        FilterType.CHECKBOX,
        (value) => {
          const user = users.find((u) => u.userId === value)
          return user ? user.fullname : t('Автоматически')
        },
      ),
    )

    setDataMenuConfig(() => [...baseFilters, ...filters])
  }, [availableFilters, currentTab])

  return { dataMenuConfig }
}

type TTabAction = {
  [key in EUploadedFileTab]: {
    /** Контент рядом с табом */
    extraContent: ReactNode | string
    label: string
    /** Дополнительная инфа рядом с названием таба (Кол-во файлов, название файла и тд )*/
    labelExtra: number | string
    /** Колбек при закрытии таба */
    onClose: () => void
    tabType: EUploadedFileTab
    dataTestId?: string
  }
}

export const useTabAction = () => {
  const { currentTab } = useUploadedFileQueryParams()
  const { resetSelectedRows, selectedFiles } = useUploadedFileTabContext()
  const { handleRefetchPage } = useRefetchUploadedFilePages()
  const { searchString, setSearchString } = useUploadedFileContext()
  const dispatch = useDispatch()
  const dupBarcode = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)

  const onTrashActionSuccess = async () => {
    await handleRefetchPage()
    resetSelectedRows()
  }

  const { isLoading: isRestoring, mutate: restoreBinTrash } = useRestoreBinTrash({
    onSuccess: onTrashActionSuccess,
  })
  const { isLoading: isDeleting, mutate: deleteFiles } = useDeleteUploadFiles({ onSuccess: onTrashActionSuccess })
  const isLoading = isDeleting || isRestoring

  const onRestoreBinTrash = () => {
    restoreBinTrash({ uploadedFileIds: selectedFiles.map((file) => file.uploadedFileId) })
  }

  const onDelete = () => {
    deleteFiles({ uploadedFileIds: selectedFiles.map((file) => file.uploadedFileId) })
  }

  const handleDuplicateTabClose = () => {
    dispatch(viewerPageSlice.actions.setSumpDuplicateBarCodeShowNumber(''))
  }

  const handleSearchTabClose = () => {
    setSearchString('')
  }

  const tabActions: Partial<TTabAction> = {
    [EUploadedFileTab.SELECTED]: {
      dataTestId: EUploadedFileTab.SELECTED,
      extraContent: (
        <>
          <ButtonElement data-testid="restore-file-from-trash-btn" onClick={onRestoreBinTrash} disabled={isLoading}>
            {t('Восстановить')}
          </ButtonElement>
          <ButtonElement data-testid="delete-file-from-trash-btn" onClick={onDelete} disabled={isLoading}>
            {t('Удалить')}
          </ButtonElement>
        </>
      ),
      label: t('Выбрано'),
      labelExtra: selectedFiles.length,
      onClose: resetSelectedRows,
      tabType: EUploadedFileTab.DELETED,
    },
    [EUploadedFileTab.SEARCH]: {
      extraContent: '',
      label: t('Поиск'),
      labelExtra: '',
      onClose: handleSearchTabClose,
      tabType: EUploadedFileTab.SEARCH,
    },
    [EUploadedFileTab.DUPLICATE]: {
      extraContent: '',
      label: t('Дубликаты слайда'),
      labelExtra: dupBarcode,
      onClose: handleDuplicateTabClose,
      tabType: EUploadedFileTab.DUPLICATE,
    },
  }

  const getTabAction = () => {
    const isSearching = !!searchString?.length
    if (isSearching) return tabActions[EUploadedFileTab.SEARCH]
    if (selectedFiles.length && currentTab === EUploadedFileTab.DELETED) return tabActions[EUploadedFileTab.SELECTED]
    if (dupBarcode.length) return tabActions[EUploadedFileTab.DUPLICATE]

    return null
  }

  const tabAction = getTabAction()

  if (!tabAction) return null

  const { dataTestId, extraContent, label, labelExtra, onClose, tabType } = tabAction

  const actionTab: TTabItem[] = [
    {
      dataTestId,
      id: tabType,
      label: (
        <div style={{ alignItems: 'center', display: 'flex', gap: 5, marginLeft: -10 }}>
          <Center data-testid="close-single-tab-btn">
            <StyledIconElement name={'cross16'} onClick={onClose} size={'md'} />
          </Center>
          {label}
        </div>
      ),
      specialCount: labelExtra,
      tabType,
    },
  ]

  return { actionTab, extraContent, isLoading, tabType }
}

export const useRefetchUploadedFilePages = () => {
  const currentPageIndex = getCurrentPageIndex()
  const { currentTab } = useUploadedFileQueryParams()
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[currentTab])
  const queryClient = useQueryClient()
  const wsId = Number(useCurrentWorkspaceId())

  const handleRefetchPage = async (tab?: EUploadedFileTab) => {
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE, tab ?? currentTab, JSON.stringify(filters), wsId], {
      refetchPage: (_, index) => [currentPageIndex - 1, currentPageIndex, currentPageIndex + 1].includes(index),
    })
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE_COUNT, wsId])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILES_FILTERS, tab ?? currentTab, wsId])
  }

  return { handleRefetchPage }
}

const StyledIconElement = styled(IconElement)`
  color: var(--color-text-3);

  &:hover {
    color: var(--color-text-1);
  }
`
