import markupTasksService from 'entities/tasks/api/service'
import { QueryFlags, updateAnnotationsQuery } from 'features/annotations/api'
import annotationsService from 'features/annotations/api/service'
import { selectTasksViewerUrlTaskId, selectUrlSlideId } from 'pages/viewer/model/viewerPageSlice'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { IAnnotation, IAnnotationStack } from 'types/IAnnotations'
import { IMarkupSlideResult } from 'types/IMarkupSlide'
import { MAXIMUM_ANNOTATIONS_STACK_SIZE } from 'viewer/map/layers/annotations/lib/constants'

export const useDeleteAnnotationMutation = (
  {
    caseId,
    currentUserId,
    needQueryUpdate,
  }: {
    /** Id кейса */
    caseId: number
    /** Id слайда */
    currentUserId?: number
    /** Включение встроенного обновления query*/
    needQueryUpdate?: boolean
  },
  options?: UseMutationOptions<unknown, unknown, { annotation: IAnnotation; noCashing?: boolean }, IAnnotation | null>,
) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currSlideId = useSelector(selectUrlSlideId)

  return useMutation(async ({ annotation, noCashing }: { annotation: IAnnotation; noCashing?: boolean }) => {
    const slideAnnotationId = annotation.slideAnnotationId
    if (!annotation) return
    const slideId = annotation?.slideId
    if (slideAnnotationId > 0) {
      if (taskId) {
        const taskSlideResult = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, currSlideId])
        const currentTaskResult = taskSlideResult?.find((item) => item.markupParticipant?.userId === currentUserId)
        await markupTasksService.deleteTaskAnnotation(
          taskId,
          currentTaskResult?.markupSlideId,
          annotation?.markupResultId,
          slideAnnotationId,
        )
      } else {
        await annotationsService.deleteAnnotation(caseId, slideId, slideAnnotationId)
      }
    }
    const updatedSlideId = taskId ? currSlideId : slideId
    updatedSlideId &&
      needQueryUpdate &&
      updateAnnotationsQuery({
        caseId,
        flag: QueryFlags.MULTIDEL,
        ids: [slideAnnotationId],
        queryClient,
        slideId: updatedSlideId,
      })
    const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, currSlideId]) || []
    !noCashing &&
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATIONS_STACK, currSlideId],
        [
          ...currentStack.slice(
            currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
            MAXIMUM_ANNOTATIONS_STACK_SIZE,
          ),
          {
            annotation: annotation,
            type: 'del',
          },
        ],
      )
  }, options)
}
