import { TAnnotationData } from 'features/annotations/api/query'
import { Feature, View } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry } from 'ol/geom'
import Select from 'ol/interaction/Select'
import { MutableRefObject } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import IUser from 'types/IUser'
import { createPolygonAreaLabel, getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { getRandomInt, getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

const t = i18next.t

type MitosisData =
  | {
      mitosis: number
      targetArea: number
    }
  | undefined

/** Минимальное количество аннотаций для операций Объединение, Вычитание, Пересечение, Исключение пересечения */
export const MIN_SELECTED_ANNOTATIONS_TO_MERGE = 2

export const getMitosisData = (annotation: IAnnotation | undefined): MitosisData => {
  const isMitosis = annotation?.type === AnnotationType.MITOSIS
  const isObjects = annotation?.type === 'OBJECTS'
  if (!isMitosis && !isObjects) return
  return {
    mitosis: annotation?.info?.mitosis,
    targetArea: annotation?.metric,
  }
}

export const createObjectsCaption = (
  type: AnnotationType.MITOSIS | 'OBJECTS',
  mitosisData: MitosisData,
  caption?: string,
) =>
  `${type === AnnotationType.MITOSIS ? t('Митозы') : caption || t('Клетки')}: ${mitosisData?.mitosis} ${t('на')} ${
    mitosisData?.targetArea
  } ${t('мм²')}`

export const getDescriptionRawHTML = (
  geometry: Geometry | undefined,
  isVisibleAreaLabel: boolean,
  type: AnnotationType,
  description: string | undefined,
  mppX: number,
  mitosisData?: MitosisData,
) => {
  const area =
    type === AnnotationType.POINT || type === AnnotationType.ARROW || geometry === undefined
      ? null
      : getPolygonArea(geometry, mppX)
  if (area === null || !isVisibleAreaLabel) return description ? `<span>${description}</span>` : ``
  const areaLabel = createPolygonAreaLabel(area, type === AnnotationType.RULER ? 'line' : 'area')
  if (isObjectsCounting(type) && mitosisData) {
    return createObjectsCaption(type as ObjectsAnnotationType, mitosisData, description)
  }
  if (description === undefined || description.length === 0) return `<span>${areaLabel}</span>`
  return `<span>${description}<br/> ${areaLabel}<span>`
}

export type ObjectsAnnotationType = AnnotationType.MITOSIS | 'OBJECTS'
export const isObjectsCounting = (type?: AnnotationType) => type === AnnotationType.MITOSIS || type === 'OBJECTS'

export const selectAnnotationsSlideId = (selectAnnotation: MutableRefObject<Select>) => {
  if (selectAnnotation.current) {
    const features = selectAnnotation.current.getFeatures().getArray()
    if (features.length > 0) {
      return features[0].get('slideAnnotationId')
    }
  }
}

export const createLocalAnnotation = ({ data, metric, slideId, type, zindex }: Partial<IAnnotation>, user?: IUser) => {
  const MIN_LOCAL_ID = -1000000000
  const MAX_LOCAL_ID = 0
  const slideAnnotationId = getRandomInt(MIN_LOCAL_ID, MAX_LOCAL_ID)
  const stringDate = new Date().toISOString()

  return {
    createdAt: stringDate,
    data,
    description: '',
    metric,
    slideAnnotationId,
    slideId,
    type,
    updatedAt: stringDate,
    user: user?.fullname || '',
    userId: Number(user?.userId),
    zindex,
  }
}

/** Запоминаем положение слайда для аннотации */
export const setSlidePositioningForAnnotation = (feature: Feature<any>, view: View) => {
  feature.set('slideCenter', view.getCenter())
  feature.set('slideResolution', view.getResolution())
  feature.set('slideRotation', view.getRotation())
}

/** Удаляем положение слайда для аннотации */
export const unsetSlidePositioningForAnnotation = (feature: Feature<any>) => {
  feature.unset('slideCenter')
  feature.unset('slideResolution')
  feature.unset('slideRotation')
}

/**
 * Обновляет описание аннотации и соответствующий `GeoJSON` объекта `feature`.
 *
 * @param {TAnnotationData} annotation - Данные аннотации, которые нужно обновить.
 * @param {string} newDescription - Новое описание для аннотации.
 * @returns {TAnnotationData} - Обновлённые данные аннотации с новым описанием.
 */
export const updateAnnotationDescription = (annotation: TAnnotationData, newDescription: string): void => {
  const feature = getSingleFeatureFromGeoJson(annotation.data?.formattedFeature)

  if (!feature) return

  feature.set('description', newDescription)

  annotation.caption = newDescription
  annotation.data = {
    formattedFeature: new GeoJSON().writeFeature(feature),
    type: 'ANNOTATION',
  }
}
