import { Divider } from 'antd'
import { useUpdateSlideDefectStatus } from 'entities/slide/api/query'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContext } from 'shared/lib/map'
import { ButtonElement } from 'shared/ui/kit'
import ToolOpacityController from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components'
import { ARTEFACT_CLASS_COLOR, ARTEFACT_CLASS_NAME, ArtefactClasses } from 'types/IArtefact'
import { DataLayerTypes } from 'types/ISlideDataLayer'
import { useArtefactsContext } from 'viewer/map/layers/artefacts/ArtefactsContext'
import { getCenterOfExtent } from 'viewer/map/lib/utils'
import { useSlideDataLayers } from 'viewer/tools/api/query'

import ArtefactsClassElement, { ArtefactsSumElement } from './ArtefactsClassElement'

const StyledText = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 11px;
  color: var(--color-text-3);
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  width: 100%;
`

const WideButton = styled(ButtonElement)`
  width: 100%;
`

/** Продолжительность анимации в милисекундах */
const ANIMATION_DURATION = 250

const ArtefactsContainer: React.FC = () => {
  const { t } = useTranslation()
  const { map } = useContext(MapContext)
  const { features, opacity, setHoveredClass, setOpacity } = useArtefactsContext()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { refetchCaseGrouped, slides } = useSlideGrouped(caseId)
  const { data: dataLayers } = useSlideDataLayers({ caseId, slideId })
  const artefactsDataLayer = dataLayers?.find((dataLayer) => dataLayer.type === DataLayerTypes.ARTEFACTS)

  const slideDefect = slides.find((slide) => slide.slideId === slideId)?.slideDefect
  const isFalsePositive = slideDefect === 'FALSE_POSITIVE'

  const updateSlideDefectMutation = useUpdateSlideDefectStatus(
    caseId,
    slideId,
    artefactsDataLayer?.slideDataLayerId || 0,
  )

  const onSlideMark = () => {
    if (artefactsDataLayer) {
      updateSlideDefectMutation.mutate(!isFalsePositive, {
        onSuccess: () => {
          refetchCaseGrouped()
        },
      })
    }
  }

  const onSelectElementHandle = (selectedClass: string) => {
    const feature = features.find((f) => f.get('class') === selectedClass)
    const geometry = feature?.getGeometry()
    if (geometry) {
      map.getView().fit(geometry.getExtent(), {
        duration: ANIMATION_DURATION,
      })
      map.getView().animate({
        center: getCenterOfExtent(geometry.getExtent()),
        duration: ANIMATION_DURATION,
      })
    }
  }
  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledText style={{ textAlign: 'left' }}>{t('Тип дефекта')}</StyledText>
        <StyledText style={{ textAlign: 'right' }}>{t('% от ткани')}</StyledText>
      </div>
      <div>
        {features.map((it) =>
          it.get('class') === ArtefactClasses.artefact_union ? (
            <ArtefactsSumElement key={it.get('class')} totalArea={it.get('tissue_percentage')} />
          ) : (
            <ArtefactsClassElement
              key={it.get('class')}
              area={it.get('tissue_percentage') || 0}
              color={ARTEFACT_CLASS_COLOR[it.get('class') as ArtefactClasses]}
              title={t(ARTEFACT_CLASS_NAME[it.get('class') as ArtefactClasses])}
              className={it.get('class')}
              onMouseEnterHandle={setHoveredClass}
              onMouseLeaveHandle={setHoveredClass}
              onClickHandle={onSelectElementHandle}
            />
          ),
        )}
      </div>
      <Divider />
      <ToolOpacityController value={opacity} onChange={setOpacity} />
      <Divider />
      <WideButton onClick={onSlideMark}>
        {slideDefect === 'FALSE_POSITIVE' ? t('Верный дефект') : t('Ложная детекция дефектов')}
      </WideButton>
    </Wrapper>
  )
}

export default ArtefactsContainer
