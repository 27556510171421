import { Divider } from 'antd'
import Input from 'antd/es/input'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useChangeAnnotationMutation } from 'features/annotations/api'
import GeoJSON from 'ol/format/GeoJSON'
import { LineString } from 'ol/geom'
import MultiPolygon from 'ol/geom/MultiPolygon'
import Polygon from 'ol/geom/Polygon'
import { selectTasksViewerUrlTaskId, viewerPageSlice } from 'pages/viewer'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { CheckboxElement, InputElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { createPolygonAreaLabel, getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { updateAnnotationDescription } from 'viewer/map/layers/annotations/lib/helpers'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'
import { THOUSAND } from 'viewer/tools/ui/artefacts/helpers'

import DefectSelectContainer from './DefectSelectContainer'

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
`

const StyledInput = styled(Input)`
  background: var(--color-bg-3);

  .ant-input-suffix {
    color: var(--color-text-3);
  }

  .ant-input {
    background: none;
    color: var(--color-text-1);
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 1px var(--color-purple);
  }
`

export const StyledCheckboxElement = styled(CheckboxElement)`
  .ant-checkbox + span {
    padding-right: 0px;
  }
`

type Props = {
  /** Id вьювера */
  viewerId: TViewerId
  /** Разрешение слайда */
  mppX: number
  /** Аннотация, для которой открывается контекстное меню */
  annotation: IAnnotation | undefined
  /** Блокировка ui для аннотаций стороннего пользователя */
  inputDisabled?: boolean
}

const INPUT_TIMEOUT = 500

export const AnnotationDescriptionAndAreaControl = ({ annotation, inputDisabled, mppX, viewerId }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { caseId, slideGroupType, slideId } = useViewerIdSlideState(viewerId)
  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const isVisibleAreaLabel = !!slide?.slideMetadata?.commonMetadata?.mppX
  const { isSuccess: isChangeAnnotationSuccess, mutate: editAnnotation } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })
  const { t } = useTranslation()
  const feature = useMemo(() => {
    if (!annotation?.data?.formattedFeature) return null
    return getSingleFeatureFromGeoJson(annotation.data.formattedFeature)
  }, [annotation])

  const isTaskViewer = !!useSelector(selectTasksViewerUrlTaskId)

  const [area, setArea] = useState<number>()

  const annotationArea = useMemo(() => {
    let metric = 0
    if (!annotation?.data) return 0
    const annotationF = getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature)
    const geom = annotationF.getGeometry()
    if (geom) {
      metric = getPolygonArea(geom, mppX)
    }
    return metric || annotation.metric || 0
  }, [annotation, mppX])

  useEffect(() => {
    if (!area) return
    changeAreaHandler(area)
  }, [area])

  const changeAreaHandler = (newArea: number) => {
    if (!annotationArea || !annotation) return

    let scale = 1
    const annotationF = getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature)
    const geom = annotationF.getGeometry()

    if (geom instanceof Polygon || geom instanceof MultiPolygon) {
      scale = Math.sqrt(newArea / annotationArea)
    } else if (geom instanceof LineString) {
      scale = newArea / annotationArea
    }

    geom.scale(scale)
    annotationF.setGeometry(geom)
    editAnnotation({
      ...annotation,
      data: {
        formattedFeature: new GeoJSON().writeFeature(annotationF),
        type: 'ANNOTATION',
      },
      metric: newArea,
    })
  }

  const description = annotation?.caption || feature?.get('description') || ''

  /**
   * Обработчик изменения описания аннотации.
   *
   * @param {string} value - Новое значение для описания аннотации.
   */
  const handleChangeDescription = (value: string) => {
    if (!annotation) return

    const updatedAnnotation = { ...annotation }

    updateAnnotationDescription(updatedAnnotation, value)

    editAnnotation(updatedAnnotation)
  }

  const isNewDescriptionValueValid = (value: string) =>
    /^[a-zA-ZА-Яа-я0-9\s.,?!":ё\-–—_\/()%*^<>#\[\]~`+=\${}]*$/.test(value) && value.length <= 30

  //@ts-ignore
  const applyDescriptionTimeout = useRef<Timeout>(null)
  const { liteContextMenuVisibility } = useTypedSelector((state) => state.viewerPage)

  const handleInputValue = useMemo(
    () => createPolygonAreaLabel(annotationArea, annotation?.type === AnnotationType.RULER ? 'line' : 'size', false),
    [annotation, annotationArea],
  )

  const [tmpDesc, setTmpDesc] = useState<string>(description)

  useEffect(() => {
    setTmpDesc(description)
  }, [description])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (applyDescriptionTimeout.current) clearTimeout(applyDescriptionTimeout.current)
    const { value } = e.target
    if (!isNewDescriptionValueValid(value)) return
    setTmpDesc(value)
    applyDescriptionTimeout.current = setTimeout(() => {
      handleChangeDescription(value)
    }, INPUT_TIMEOUT)
  }

  const onFocusCapture = (evt: React.FocusEvent<HTMLInputElement>) => evt.target.select()

  return (
    <>
      <Wrapper style={{ padding: '16px 16px 8px 16px' }}>
        <InputElement
          autoFocus
          placeholder={t('Подпись к аннотации')}
          data-testid="annotation-description-input"
          onFocus={() => setIsAnyInputFocusing(true)}
          onBlur={() => setIsAnyInputFocusing(false)}
          value={tmpDesc}
          onChange={onChangeHandler}
          onFocusCapture={onFocusCapture}
          disabled={inputDisabled}
        />
        {annotation?.type &&
          isVisibleAreaLabel &&
          [AnnotationType.RECTANGLE, AnnotationType.POLYGON, AnnotationType.CIRCLE, AnnotationType.RULER].includes(
            annotation?.type,
          ) &&
          slideGroupType !== 'MACRO' && (
            <div style={{ marginLeft: '8px', width: 'calc(42% + 8px)' }}>
              <StyledInput
                suffix={`мм${annotation?.type !== AnnotationType.RULER ? '²' : ''}`}
                defaultValue={handleInputValue}
                value={handleInputValue}
                disabled={inputDisabled}
                onChange={(e) => {
                  annotation?.type === AnnotationType.RULER
                    ? setArea(+(+e.target.value * THOUSAND).toFixed(2))
                    : setArea(+(+e.target.value * THOUSAND ** 2).toFixed(2))
                }}
              />
            </div>
          )}
      </Wrapper>
      <Divider />
      {annotation && !isTaskViewer && (
        <>
          <Wrapper>
            <DefectSelectContainer
              annotation={annotation}
              editAnnotation={editAnnotation}
              isChangeAnnotationSuccess={isChangeAnnotationSuccess}
              caseId={caseId}
            />
          </Wrapper>
          <Divider />
        </>
      )}
      <Wrapper>
        <StyledCheckboxElement
          checked={liteContextMenuVisibility}
          onChange={(e) => {
            dispatch(viewerPageSlice.actions.setLiteContextMenuVisibility(e.target.checked))
          }}
        >
          {t('Показывать после рисования аннотации')}
        </StyledCheckboxElement>
      </Wrapper>
    </>
  )
}
