import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

interface OnDetectDefectsNotificationProps {
  message?: string
}

const OnDetectDefectsNotification: React.FC<OnDetectDefectsNotificationProps> = ({ message }) => (
  <TextWrapper>{message}</TextWrapper>
)

export default OnDetectDefectsNotification
