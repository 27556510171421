import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { SEARCH_BUFFER } from 'pages/sump/lib/constants'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'shared/lib/hooks'
import { SearchElement } from 'shared/ui/kit'

export const UploadedFileSearch = () => {
  const { searchString: searchQuery, setSearchString } = useUploadedFileContext()
  const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_BUFFER)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value)
  }

  useEffect(() => {
    setSearchString(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const { t } = useTranslation()

  return (
    <SearchElement
      data-testid="uploaded-file-search-input"
      value={searchQuery}
      onChange={handleChange}
      placeholder={`${t(`Поиск`)}...`}
      style={{ width: 190 }}
    />
  )
}
