import { Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { useTypedSelector } from 'app/redux/lib/selector'
import { EUploadedFileFilterType, updateDateRangeFilter } from 'features/uploaded-file/lib/common'
import { useUploadedFileFilters, useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import { viewerPageSlice } from 'pages/viewer'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CheckboxElement } from 'shared/ui/kit'
import {
  convertDateForQuery,
  DateType,
  FilterType,
  IActiveFilter,
  ISubMenu,
  ISubMenuItem,
  ITableRequestConfig,
  updateFilters,
} from 'shared/ui/table/lib/common'
import { DateMenuItem, SubMenuTitle, TableFilter } from 'shared/ui/table/ui/TableFilter'

const UploadFileTableFilter = () => {
  const [selectedFilters, setSelectedFilters] = useState<IActiveFilter[]>([])
  const dispatch = useDispatch()
  const { currentTab } = useUploadedFileQueryParams()
  const { dataMenuConfig } = useUploadedFileFilters()
  const [subMenuKey, setSubMenuKeyFilter] = useState('')
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[currentTab])
  const isDuplicateShow = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)
  const { selectedRowKeys } = useUploadedFileTabContext()
  const isFilterBtnDisabled = !!(isDuplicateShow || !!filters?.query || selectedRowKeys?.length)
  const handleRemoveFilter = useCallback((subMenuKey: React.Key) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter.key !== subMenuKey))
  }, [])

  const mapFiltersForRequest = (selectedFilters: IActiveFilter[]) =>
    selectedFilters.reduce<ITableRequestConfig>((acc, filter) => {
      switch (filter.key) {
        case EUploadedFileFilterType.CREATED_DATE:
        case EUploadedFileFilterType.ATTACHED_TO_CASE_DATE:
        case EUploadedFileFilterType.DELETED_DATE:
          if (filter.activeFilters.length > 0 && filter.activeFilters[0].dateRange) {
            const { key } = filter
            acc[`${key}From`] =
              convertDateForQuery(filter.activeFilters[0].dateRange?.from || null, DateType.FROM) || undefined
            acc[`${key}To`] =
              convertDateForQuery(filter.activeFilters[0].dateRange?.to || null, DateType.TO) || undefined
          }

          break
        default:
          acc[filter.key] = filter.activeFilters.map(
            ({ filterValue, queryValue }) => (queryValue !== undefined ? queryValue : filterValue) as string,
          )
      }

      return acc
    }, {})

  const handleFilterChange = (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => {
    setSelectedFilters((prevFilters) => updateFilters(prevFilters, item, e.target.checked, subMenu))
  }

  const handleDataRangeChange = (
    _: [Date | null, Date | null] | null,
    dateStrings: [string, string],
    filterType?: EUploadedFileFilterType,
  ) => {
    if (!filterType) return

    const filter = dataMenuConfig.filter((it) => it.key === filterType)

    setSelectedFilters((prevState) => updateDateRangeFilter(prevState, filter[0], filterType, dateStrings))
  }

  useEffect(() => {
    dispatch(
      viewerPageSlice.actions.setSumpFilters({
        filters: {
          sortBy: filters?.sortBy,
          sortDir: filters?.sortDir,
          ...mapFiltersForRequest(selectedFilters),
        },
        key: currentTab,
      }),
    )
  }, [selectedFilters])

  useEffect(() => {
    setSelectedFilters([])
  }, [currentTab])

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleTitleClick = (subMenuKey: string) => setSubMenuKeyFilter(subMenuKey)

  const menu = dataMenuConfig.map((subMenu) => {
    let menuContent

    const dateRange = selectedFilters.filter((it) => it.key === subMenuKey)[0]?.activeFilters[0]?.dateRange

    switch (subMenu.filterType) {
      case FilterType.SINGLE_SELECT:
      case FilterType.CHECKBOX:
        menuContent = subMenu.items.map((item, index) => (
          <React.Fragment key={`${item.key || index}-${index}`}>
            <Menu.Item className="cases-filter-submenu-item">
              {/** Обёртка для предотвращения закрытия саб меню при клике */}
              <div onClick={(e) => e.stopPropagation()}>
                <CheckboxElement
                  style={{ alignItems: 'center' }}
                  onChange={handleFilterChange(item, subMenu)}
                  checked={isFilterActive(item.key)}
                >
                  {item.label}
                </CheckboxElement>
              </div>
            </Menu.Item>
          </React.Fragment>
        ))
        break

      case FilterType.DATA_RANGE:
        menuContent = (
          <DateMenuItem
            handleDataRangeChange={(dates, dateStrings) =>
              handleDataRangeChange(dates, dateStrings, subMenu.key as EUploadedFileFilterType)
            }
            valueFrom={dateRange?.from}
            valueTo={dateRange?.to}
            open={subMenu.key === subMenuKey}
          />
        )

        break

      default:
        menuContent = null
    }

    return (
      <Menu.SubMenu
        data-testid={`${subMenu.key.toLowerCase()}-filter-menu-item`}
        key={subMenu.key}
        popupClassName={
          subMenu.filterType === FilterType.DATA_RANGE ? 'submenu-filter-datarange-style' : 'submenu-filter-style'
        }
        title={<SubMenuTitle title={subMenu.title} />}
        onTitleClick={() => handleTitleClick(subMenu.key)}
      >
        {menuContent}
      </Menu.SubMenu>
    )
  })

  return (
    <TableFilter
      disabled={isFilterBtnDisabled}
      handleDataRangeChange={handleDataRangeChange}
      menu={menu}
      ignoreCaseRouting
      handleFilterChange={handleFilterChange}
      selectedFilters={selectedFilters}
      handleRemoveFilter={handleRemoveFilter}
      dataMenuConfig={dataMenuConfig}
      setSubMenuKeyFilter={setSubMenuKeyFilter}
    />
  )
}

export default UploadFileTableFilter
