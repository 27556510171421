import { useTypedSelector } from 'app/redux/lib/selector'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { TopBorder } from 'shared/ui/divider'
import { ButtonElement, IconElement, ImageElement, TextElement } from 'shared/ui/kit'
import { BetaBanner } from 'shared/ui/kit/ui/BetaBanner'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { DatalayerStatus } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { TGridTool } from 'viewer/tools'
import { useSlideDataLayers } from 'viewer/tools/api/query'

import { useAccessRightsMap } from './hooks/useAccessRightsMap'
import { ML_TOOLS_MAP } from './MlToolsMap'

type Props = {
  viewerId: TViewerId
}

const Body = styled.div`
  display: grid;
  grid-template-columns: 248px 320px;
`

const ToolsList = styled.div`
  padding: 16px;
  width: 100%;
  border-right: 1px solid var(--color-border-1);
`

const Tool = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? 'var(--color-purple)' : 'transparent')};
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: ${({ isActive }) => (isActive ? 'white' : 'var(--color-text-1)')};
  &:hover {
    background: var(--color-purple);
    color: white;
    cursor: pointer;
  }
`

const Preview = styled.div`
  display: grid;
  padding: 16px;
  gap: 16px;
`

const InfoImage = styled(ImageElement)`
  width: 288px;
  height: 216px;
  border-radius: 5px;
  background-color: var(--color-bg-3);
`

const Info = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: var(--color-bg-3);
  border-radius: 5px;

  font-size: 11px;
  line-height: 12px;

  & > *:first-child {
    flex: 0 0 16px;
  }
`

const ToolsModalContainer = ({ viewerId }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { data: dataLayers } = useSlideDataLayers({ caseId, slideId })
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const permissionLevel = caseRecord?.permissionLevel
  const { accessRightsMap } = useAccessRightsMap()
  const [activeTool, setTool] = useState<TGridTool | null>(null)
  const { isMlToolsModalOpen } = useViewerMainSelector(viewerId)
  const modalRef = useRef<any>()
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { openViewerIds } = useOpenViewers()
  const onClose = useCallback(() => {
    viewerDispatch(viewerSlice.actions.setMlModal(false))
  }, [])
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { artefactsVisibility } = useTypedSelector((state) => state.viewerPage)

  const activeToolMap = useMemo(() => {
    if (activeTool === null) return null
    return ML_TOOLS_MAP[activeTool]
  }, [accessRightsMap.current, activeTool])

  useEffect(() => {
    isMlToolsModalOpen ? modalRef.current.open() : modalRef.current.close()
  }, [isMlToolsModalOpen])

  const onOpenMitosisTool = useCallback(() => {
    dispatch(viewerSlice.actions.selectObjectId())
    dispatch(viewerPageSlice.actions.openObjectsCounting())
    dispatch(viewerPageSlice.actions.setCountingObjectType('MITOSIS'))
  }, [activeTool])

  const toggleArtefactsPanel = (isVisible: boolean) => {
    viewerDispatch(viewerPageSlice.actions.setArtefactsVisibility({ slideId, value: isVisible }))
  }

  const openTool = useCallback(() => {
    if (activeToolMap === null) return
    if (activeTool === 'ARTEFACTS') {
      const isCurrentlyVisible = artefactsVisibility[slideId] ?? false
      toggleArtefactsPanel(!isCurrentlyVisible)
      viewerDispatch(viewerSlice.actions.setMlModal(false))
      return
    }
    if (activeTool === 'MITOSIS') {
      if (MITOSIS.isVisible) {
        dispatch(viewerPageSlice.actions.setWhetherToOpenObjectsContext(false))
        setTimeout(() => dispatch(viewerPageSlice.actions.setTool({ tool: 'MITOSIS', value: false })))
      }

      setTimeout(onOpenMitosisTool)

      viewerDispatch(viewerSlice.actions.setMlModal(false))
      return
    }
    viewerDispatch(viewerSlice.actions.setMlModal(false))
    // @ts-ignore
    viewerDispatch(viewerPageSlice.actions.setTool({ tool: activeToolMap.id, value: true }))
  }, [activeToolMap])

  // set initial active state for tool
  useEffect(() => {
    if (activeTool || !currentWorkspace) return
    let setted = false
    Object.keys(ML_TOOLS_MAP).forEach((key: string) => {
      if (setted) return
      if (accessRightsMap.current.get(key) === true) {
        setTool(key as TGridTool)
        setted = true
      }
    })
  }, [currentWorkspace])

  const getToolInfoText = () => {
    const blackList: TGridTool[] = ['HEAT_MAP', 'KI67', 'SEGMENTATION', 'VALIDATION']
    if (!activeTool || blackList.includes(activeTool)) return

    const currentLayer = dataLayers?.find((dataLayer) => dataLayer.type === activeTool)
    const isLayerInProgress = currentLayer && currentLayer.inProgress
    const isLayerEmptyResult = currentLayer && currentLayer.emptyResult
    const isLayerStatusFailed = currentLayer && currentLayer?.status === DatalayerStatus.FAILED
    if (openViewerIds.length !== 1) {
      return t(ML_TOOLS_MAP[activeTool].infoMaxViewCountText ?? '')
    }
    if (isLayerStatusFailed) {
      return t(ML_TOOLS_MAP[activeTool].infoStatusFailedText ?? '')
    }
    if (isLayerInProgress) {
      return t(ML_TOOLS_MAP[activeTool].infoInProgressText ?? '')
    }
    if (isLayerEmptyResult) {
      return t(ML_TOOLS_MAP[activeTool].infoEmptyResultText ?? '')
    }
    if (!currentLayer) {
      return t(ML_TOOLS_MAP[activeTool].infoDisabledText ?? '')
    }
  }

  const getIsToolDisabled = () => {
    const blackList: TGridTool[] = ['HEAT_MAP', 'KI67', 'SEGMENTATION', 'VALIDATION']
    if (!activeTool || blackList.includes(activeTool)) return false
    const currentLayer = dataLayers?.find((dataLayer) => dataLayer.type === activeTool)
    return (
      !currentLayer ||
      currentLayer.inProgress ||
      currentLayer?.status === DatalayerStatus.FAILED ||
      currentLayer.emptyResult ||
      openViewerIds.length !== 1
    )
  }

  return (
    <ModalProvider
      ref={modalRef}
      title={t('Инструменты')}
      destroyOnClose
      onCancel={onClose}
      footer={null}
      width={570}
      bodyStyle={{
        padding: 0,
      }}
    >
      <TopBorder />
      <Body>
        <ToolsList data-testid={'tools-ai-names-block'}>
          {Object.values(ML_TOOLS_MAP).map((tool) => {
            if (
              accessRightsMap.current.get(tool.id) === false ||
              (permissionLevel === 'READ_ONLY' && tool.id === 'MITOSIS')
            )
              return null
            const handleToolClick = () => {
              setTool(tool.id)
              tool.id === 'ARTEFACTS' && toggleArtefactsPanel(true)
            }
            return (
              <Tool
                key={tool.id}
                isActive={activeTool === tool.id}
                data-testid={`${tool.id}-btn-AI-menu`}
                onClick={handleToolClick}
              >
                {t(tool.title)}
              </Tool>
            )
          })}
        </ToolsList>
        <Preview data-testid={'tools-ai-description'}>
          {activeTool && <InfoImage src={ML_TOOLS_MAP[activeTool].src} />}
          {activeTool && <TextElement>{t(ML_TOOLS_MAP[activeTool].description)}</TextElement>}
          {getIsToolDisabled() ? (
            <Info>
              <IconElement name="infoSmall" fill="var(--color-text-3)" />
              <span>{getToolInfoText()}</span>
            </Info>
          ) : (
            <ButtonElement onClick={openTool}>{t('Открыть')}</ButtonElement>
          )}
          <BetaBanner />
        </Preview>
      </Body>
    </ModalProvider>
  )
}

export default ToolsModalContainer
