import { Divider } from 'antd'
import { annotationsSlice } from 'features/annotations'
import { TAnnotationData } from 'features/annotations/api/query'
import GeoJSON from 'ol/format/GeoJSON'
import React from 'react'
import { CheckboxElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IAnnotation } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { getFeaturesFromGeoJson } from 'viewer/map'
import { getCustomColor } from 'viewer/map/layers/olStyles'

const StyledClassBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledHotkey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-3);
  color: var(--color-text-1);
  width: 12px;
  height: 14px;

  box-shadow: 0px 1px 0px 1px #000000;
  border-radius: 2px;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
`

const StyledCheckboxElement = styled(CheckboxElement)<{ backgroundColor: string }>`
  font-size: 12px;
  line-height: 16px;

  :first-child {
    margin-top: 0;
  }

  margin-left: 0 !important;

  .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: rgba(${({ backgroundColor }) => backgroundColor}, 1);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(${({ backgroundColor }) => backgroundColor}, 1);
    border: none;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    margin-left: 1px;
  }
`

const StyledClassesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0;
`

type ClassesBlockProps = {
  /** Id вьювера */
  viewerId: TViewerId
  /** Кастомные классы аннотаций в задаче */
  currTaskClasses: IMarkupClass[]
  /** Массив обновляемых аннотаций */
  annotationsForHandler: (IAnnotation | undefined)[]
  /** Функция редактирования */
  editAnnotation: (AnnotationData: TAnnotationData) => void
  /** Доступность элементов UI в зависимости от прав */
  accessCheck: boolean
}
export const AnnotationClassesBlock = ({
  accessCheck,
  annotationsForHandler,
  currTaskClasses,
  editAnnotation,
  viewerId,
}: ClassesBlockProps) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const checkHandler = (name: string) => {
    const classNames = annotationsForHandler.reduce((names, annotation) => {
      const name = getFeaturesFromGeoJson(annotation?.data?.formattedFeature)[0].get('class')
      if (name !== undefined) names.add(name)
      return names
    }, new Set<string>())

    return classNames.size > 1 ? false : classNames.has(name)
  }

  const changeClassHandler = (selectedClass: string) => {
    const updatedFeatures = annotationsForHandler.map(
      (annotation) => getFeaturesFromGeoJson(annotation?.data?.formattedFeature)[0],
    )

    updatedFeatures.forEach((feature) => feature.set('class', selectedClass))
    viewerDispatch(annotationsSlice.actions.setCurrentClass(selectedClass || null))
    annotationsForHandler?.forEach(
      (annotation, index) =>
        annotation &&
        editAnnotation({
          data: {
            formattedFeature: new GeoJSON().writeFeature(updatedFeatures[index]),
            type: 'ANNOTATION',
          },
          metric: annotation.metric,
          slideAnnotationId: annotation.slideAnnotationId,
          type: annotation.type,
        }),
    )
  }
  const availableClasses = currTaskClasses.filter(
    (classItem) => accessCheck && annotationsForHandler?.find((item) => item?.type === classItem.annotationType),
  )

  return (
    <>
      <StyledClassesList>
        {availableClasses.map((item) => (
          <StyledClassBlock key={item.markupClassId}>
            <StyledCheckboxElement
              key={item.name}
              checked={checkHandler(item.name)}
              onChange={() => changeClassHandler(item.name)}
              backgroundColor={getCustomColor(item?.colorAsRGBA) || ''}
            >
              {item.name}
            </StyledCheckboxElement>
            {item.keyboardShortcut && <StyledHotkey>{item.keyboardShortcut}</StyledHotkey>}
          </StyledClassBlock>
        ))}
      </StyledClassesList>
      {!!availableClasses.length && <Divider />}
    </>
  )
}
