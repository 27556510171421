import { Select } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedItem, groupLabelLocalized, localizeIcd10Dictionary } from 'shared/lib/common'
import { SelectElement, SelectElementProps, SpinElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

import SelectedIcd10 from './SelectedIcd10'

const { OptGroup, Option } = Select

const SiteLabel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  width: 100%;
  white-space: normal;
  text-overflow: initial;
  padding-top: 2px;
`

const LoadingContainer = styled.div`
  color: var(--color-text-1);
  width: 100%;
  display: flex;
  gap: 8px;
`

const StyledSpineElement = styled(SpinElement)`
  display: flex;
  margin: auto;
`

type Props = {
  groups2Icd10Codes: Record<string, DictionaryItem[]>
  icd10?: DictionaryItem
  filterByVisible?: boolean
  multiline?: boolean
  onDelete: () => void
} & Omit<SelectElementProps, 'options' & 'groups'>

const Icd10SelectElement = memo(
  ({ filterByVisible = true, groups2Icd10Codes, icd10, multiline = true, onDelete, ...rest }: Props) => {
    const { t } = useTranslation()
    const [searchValue, onSearch] = useState('')
    const isLoading = useTypedSelector((state) => state.reports.isDictionaryLoading)
    const icd10Localized = icd10 && getLocalizedItem(icd10, EDictionaryNS.ICD_10)
    const dictionaryLocalized: Record<string, DictionaryItem[]> = localizeIcd10Dictionary(
      groups2Icd10Codes,
      EDictionaryNS.ICD_10,
    )
    if (icd10Localized && multiline) {
      return <SelectedIcd10 icd10={icd10Localized} onDeleteSite={onDelete} {...rest} />
    }

    return (
      <SelectElement
        {...rest}
        onSearch={onSearch}
        searchValue={searchValue}
        showSearch
        filterOption={false}
        onSelect={() => onSearch('')}
        placeholder={icd10Localized ? createOptionLabel(icd10Localized) : t('Поиск по названию или коду')}
      >
        {isLoading && !Object.entries(dictionaryLocalized).length ? (
          <LoadingContainer>
            <StyledSpineElement />
          </LoadingContainer>
        ) : (
          Object.entries(dictionaryLocalized)?.map((entries) => {
            const { icd10s, keyGroup } = groupLabelLocalized(...entries, EDictionaryNS.ICD_10)
            return createOptionGroup(keyGroup, icd10s, searchValue, filterByVisible)
          })
        )}
      </SelectElement>
    )
  },
)

const createOption = (icd10: DictionaryItem, searchValue: string) => {
  if (searchValue && !findQueryInIcd10(searchValue, icd10)) {
    return null
  }
  return (
    <Option value={icd10.id} key={icd10.id}>
      {createOptionLabel(icd10)}
    </Option>
  )
}

const createOptionGroup = (key: string, icd10s: DictionaryItem[], searchValue: string, filterByVisible: boolean) => {
  const filteredIcd10s = filterByVisible ? icd10s.filter((icd10) => icd10.visible) : icd10s
  const icd10filteredBySearch = filteredIcd10s.filter((icd10) => !!createOption(icd10, searchValue))

  if (!icd10filteredBySearch?.length) return null

  return (
    <OptGroup label={createOptionGroupLabel(key)}>
      {icd10filteredBySearch.map((icd10) => createOption(icd10, searchValue))}
    </OptGroup>
  )
}

const createOptionGroupLabel = (siteGroupeName: string) => {
  const [code, text] = siteGroupeName.split(':')
  return (
    <SiteLabel>
      <TextElement ellipsis type="secondary">
        {text}
      </TextElement>
      <TextElement type="secondary">{code}</TextElement>
    </SiteLabel>
  )
}

const createOptionLabel = (icd10: DictionaryItem) => (
  <SiteLabel>
    <TextElement ellipsis>{icd10.name}</TextElement>
    <TextElement>{icd10.shortName}</TextElement>
  </SiteLabel>
)

const findQueryInIcd10 = (query: string, icd10: DictionaryItem) =>
  icd10.name.toLowerCase().includes(query.toLowerCase()) || icd10.shortName.toLowerCase().includes(query.toLowerCase())

export default Icd10SelectElement
