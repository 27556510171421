export const THOUSAND = 1000
/**
 * Функция переводит площадь из микрометров квадратных
 * в квадратные миллиметры и округляет до определенного знака после запятой.
 * При получении 0 после округления возвращает минимальную округленную площадь
 * со знаком < (меньше)
 * @param area - площадь
 * @param fractionDigits - знаков после запятой
 * @returns string - переведенная и округленная площадь
 */
export const fromMcToMmAreaFixed = (area: number, fractionDigits: number) =>
  (area / THOUSAND / THOUSAND).toFixed(fractionDigits) === `0.${'0'.repeat(fractionDigits)}`
    ? `< 0.${'0'.repeat(fractionDigits - 1)}1`
    : (area / THOUSAND / THOUSAND).toFixed(fractionDigits)

export const roundAreaToTwoDecimals = (area: number): string => (Math.round(area * 100) / 100).toFixed(2)
