import Select, { SelectProps } from 'antd/es/select'
import { FC, ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { IconElement } from './IconElement'

const { OptGroup, Option } = Select

const StyledSelectElement = styled(Select)`
  &.ant-select {
    color: var(--color-text-1);
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 16px 0 8px;
    background: var(--color-bg-3);
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--color-text-1);
  }
  .ant-select-arrow {
    width: 16px;
    height: 16px;
    top: 4px;
    right: 2px;
    margin-top: 0;
    color: var(--color-text-3);
  }
  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 1px var(--color-purple);
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    color: var(--color-text-3);
  }
  &.ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 8px;
    color: var(--color-text-1);
  }
`

export const GlobalAntSelectStyle = createGlobalStyle`
  .ant-select-item-group {
    color: var(--color-text-3);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-bg-3);
    color: var(--color-text-1);
  }

  .ant-select-item{
    color: var(--color-text-1);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    background: var(--color-bg-3);
  }
  
  .ant-select-dropdown {
    border: 1px solid var(--color-bg-1);
    background: var(--color-bg-2);
  }
`

export type SelectOptionType = { value: number | string; label: ReactNode }
export type SelectOptionGroupType = {
  label: ReactNode
  options: SelectOptionType[]
  key: string
}
//@ts-ignore
export type SelectElementProps = Omit<SelectProps, 'onChange'> & {
  onChange?: ((value: number) => void) | ((value: string) => void)
  options?: SelectOptionType[]
  groups?: SelectOptionGroupType[]
}

export const SelectElement: FC<SelectElementProps> = ({ children, groups, onChange, options, ...rest }) => {
  if (children) {
    return (
      //@ts-ignore
      <StyledSelectElement {...rest} onChange={onChange as any} suffixIcon={<IconElement name="dropDown" size="md" />}>
        {children}
      </StyledSelectElement>
    )
  }

  return (
    //@ts-ignore
    <StyledSelectElement {...rest} onChange={onChange as any} suffixIcon={<IconElement name="dropDown" size="md" />}>
      {groups && groups.map(createOptionGroup)}
      {options && options.map(createOption)}
    </StyledSelectElement>
  )
}

const createOption = (option: SelectOptionType) => (
  <Option value={option.value} key={option.value}>
    {option.label}
  </Option>
)

const createOptionGroup = ({ key, label, options }: SelectOptionGroupType) => (
  <OptGroup label={label} key={key}>
    {options.map(createOption)}
  </OptGroup>
)
