import { Dispatch } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { logout } from 'processes/user'

const authChannel = new BroadcastChannel('auth')

export const syncLogoutAcrossTabs = (dispatch: Dispatch) => {
  const onMessage = (event: MessageEvent) => {
    if (event.data === 'logout') {
      logout()
      dispatch(push('/login'))
    }
  }

  authChannel.addEventListener('message', onMessage)

  return () => {
    authChannel.removeEventListener('message', onMessage)
  }
}

export const triggerLogout = () => {
  authChannel.postMessage('logout')
}
