import { Dropdown } from 'antd'
import { BubbleButton } from 'features/cases-management/ui/filters/BubbleButton'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { useState } from 'react'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { IconElement } from 'shared/ui/kit'
import { FilterBubbleProps, FilterType } from 'shared/ui/table/lib/common'

import { FilterText, FilterTitle } from './FilterBubble.styles'
import { subMenuDropdown } from './utils'

export const FilterBubble: React.FC<FilterBubbleProps> = ({
  activeFilter,
  handleDataRangeChange,
  handleFilterChange,
  handleRemoveFilter,
  isFilterActive,
  menuConfig,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const { currentTab } = useCaseManagementRouteParam()
  const { isCaseRouting } = useSettingsAndUserRoles()

  const handleRemoveFilterAndClose = (key: string) => (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    handleRemoveFilter(key)
    setIsDropdownVisible(false)
  }

  const renderBubbleButtonText = (): string => {
    if (activeFilter.filterType === FilterType.DATA_RANGE) {
      const dateRangeValue = activeFilter.activeFilters[0].dateRange
      return dateRangeValue ? `${dateRangeValue.from} - ${dateRangeValue.to}` : ''
    }

    const filterCount = activeFilter.activeFilters.length
    if (filterCount === 1) {
      return `${activeFilter.activeFilters[0].filterName}`
    }
    return `${filterCount}`
  }

  const toggleDropdown = () => setIsDropdownVisible((prev) => !prev)

  return (
    <Dropdown
      overlay={subMenuDropdown({
        currentTab,
        dateRangeFilter: activeFilter.activeFilters[0].dateRange,
        handleDataRangeChange,
        handleFilterChange,
        isCaseRouting,
        isDropdownVisible,
        isFilterActive,
        menuConfig,
        subMenuKey: activeFilter.key,
      })}
      trigger={['click']}
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
    >
      <BubbleButton onClick={toggleDropdown} isDropdownVisible={isDropdownVisible}>
        <FilterTitle>
          {activeFilter.title}
          {COLON_WITH_SPACE}
        </FilterTitle>
        <FilterText>{renderBubbleButtonText()}</FilterText>
        <IconElement
          className="cross-icon-bubble-button"
          size={'md'}
          defaultFill={false}
          name="cross16"
          hoverColor="var(--color-text-1)"
          onClick={handleRemoveFilterAndClose(activeFilter.key)}
        />
      </BubbleButton>
    </Dropdown>
  )
}
