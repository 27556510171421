export const pluralText = (num: number, titles: string[]) => {
  const number = Math.abs(num)
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2]
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }
  return titles[1]
}

export const declensionWord = (value: number, words: string[]) => {
  value = Math.abs(value) % 100
  const num = value % 10
  if (value > 10 && value < 20) return words[2]
  if (num > 1 && num < 5) return words[1]
  if (num == 1) return words[0]
  return words[2]
}

export const trimTextWithLastChar = (text?: string | number): string => {
  if (!text && text !== 0) return ''
  const MAX_LENGTH = 117

  const formattedText = text.toString()

  if (formattedText.length > MAX_LENGTH) {
    return formattedText.slice(0, MAX_LENGTH - 1) + '...' + formattedText.slice(-1)
  }

  return formattedText
}
