import { EDictionaryNS } from 'features/dictionaries/api/service'
import i18next from 'shared/lib/i18n/i18n'
import { I18N_LANGUAGES } from 'shared/lib/i18n/languages'
import { createCapitalString } from 'shared/lib/metadata'
import { DictionaryItem } from 'types/IDictionary'

/**
 * Проверяет число на undefined и подменяет его при положительном результате
 * @param {Type} orValue - Число для подмены
 * @param {Type | undefined} target - Число для проверки
 */
export function getIfUndefined<Type>(orValue: Type, target?: Type): Type {
  if (target === undefined) {
    return orValue
  }
  return target
}

/**
 * Очищаем пустые свойста объекта
 * @param {any} object - объект очистки
 */
export const clearEmptyProps = (object: any) =>
  Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== null && v !== '' && v !== undefined))

/** Тип словаря */
type IDictionary = Record<string, DictionaryItem[]> | DictionaryItem[]

const i18n = i18next
const t = i18n.t

/** Локализует название группы ICD-10. */
export const groupLabelLocalized = (key: string, icd10s: DictionaryItem[], dictName: EDictionaryNS) => {
  const [code, name] = key.split(':')
  const dictNameUpp = dictName.toUpperCase()
  const hasNamespace = i18n.hasLoadedNamespace(dictNameUpp)
  const keyLocales = `dictionary.def.${dictNameUpp}_GROUP.${code}--${name}`
  i18n.language === I18N_LANGUAGES.ENGLISH && !hasNamespace && i18n.loadNamespaces(dictName)
  return { icd10s: icd10s, keyGroup: i18n.exists(`${dictName}:${keyLocales}`) ? t(`${dictName}:${keyLocales}`) : key }
}

/** Преобразует элемент словаря в его локализованное представление. */
export const getLocalizedItem = (item: DictionaryItem, dictName: EDictionaryNS) => {
  const { name, shortName } = item
  const key = `dictionary.def.${dictName}.${shortName}--${name}`
  const hasNamespace = i18n.hasLoadedNamespace(dictName)
  i18n.language === I18N_LANGUAGES.ENGLISH && !hasNamespace && i18n.loadNamespaces(dictName)
  return {
    ...item,
    name: i18n.exists(`${dictName}:${key}`) ? t(`${dictName}:${key}`) : item.name,
  }
}

/** Локализует словари, представленные в виде массива элементов. */
export const localizeDictionary = (dictionary: IDictionary, dictName: EDictionaryNS) => {
  const result: DictionaryItem[] = []

  const dictNameUpp = dictName.toUpperCase()
  const hasNamespace = i18n.hasLoadedNamespace(dictNameUpp)
  i18n.language === I18N_LANGUAGES.ENGLISH && !hasNamespace && i18n.loadNamespaces(dictNameUpp)

  if (dictionary) {
    for (const [key, value] of Object.entries(dictionary)) {
      if (value) {
        result.push(getLocalizedItem(value, dictName))
      }
    }
  }
  return result
}

/** Локализует словарь ICD-10. */
export const localizeIcd10Dictionary = (dictionary: IDictionary, dictName: EDictionaryNS) => {
  const result: any = {}
  const dictNameUpp = dictName.toUpperCase()
  const hasNamespace = i18n.hasLoadedNamespace(dictNameUpp)
  i18n.language === I18N_LANGUAGES.ENGLISH && !hasNamespace && i18n.loadNamespaces([dictNameUpp])

  for (const [key, value] of Object.entries(dictionary)) {
    if (value instanceof Array) {
      result[key] = value.map((item: DictionaryItem) => getLocalizedItem(item, dictName))
    } else if (value) {
      result[key] = localizeIcd10Dictionary(value, dictName)
    } else {
      result[key] = value
    }
  }
  return result
}

/** Возвращает перевод словаря ICD-10. */
export const getIcd10Localized = (icd10?: Partial<DictionaryItem> | null) =>
  icd10 && getLocalizedItem(icd10 as DictionaryItem, EDictionaryNS.ICD_10)

/** Возвращает перевод словаря DIAGNOSTIC-PROCEDURE-TYPE. */
export const getDiagnosticProcedureLocalize = (diagnosticProcedureType?: DictionaryItem | null) =>
  diagnosticProcedureType &&
  getLocalizedItem(diagnosticProcedureType as DictionaryItem, EDictionaryNS.DIAGNOSTIC_PROCEDURE_TYPE)

/** Возвращает перевод словаря SITE. */
export const getLocalizedSiteName = (site: DictionaryItem) => {
  const siteLocalize = getLocalizedItem(site as DictionaryItem, EDictionaryNS.SITE)

  return siteLocalize.name ? createCapitalString(siteLocalize.name) : t('не указано')
}
