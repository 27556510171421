import { useTypedSelector } from 'app/redux/lib/selector'
import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { UploadedFileSearch } from 'pages/sump/ui/UploadedFileSearch'
import { viewerPageSlice } from 'pages/viewer'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EUploadedFileSortBy } from 'types/IUploadedFile'

const UploadedFileSearchContainer = () => {
  const { searchString } = useUploadedFileContext()
  const dispatch = useDispatch()
  const { currentTab } = useUploadedFileQueryParams()
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[currentTab])
  const { resetSumpSearchQuery, setSumpFilters } = viewerPageSlice.actions

  useEffect(() => {
    dispatch(
      searchString.length
        ? setSumpFilters({
            filters: { ...filters, query: searchString, sortBy: EUploadedFileSortBy.STATE, sortDir: ESortDir.ASC },
            key: currentTab,
          })
        : resetSumpSearchQuery({ key: currentTab }),
    )
  }, [searchString])

  return <UploadedFileSearch />
}

export default UploadedFileSearchContainer
